/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KitAdminState} from '@dv/kitadmin/models';
import {KinderOrt} from '@dv/kitadmin/models';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {RequiredPermission} from '@dv/shared/code';

export const GROUP_ADMIN_EINSTELLUNGEN = {
    name: 'base.kinderort.group-admin-einstellungen',
    url: '/group-admin-einstellungen',
    data: {
        subtitle: 'KINDERORT.NAV_KINDERORT_GROUP_ADMIN_EINSTELLUNGEN',
        permission(toParams: { id: string }): RequiredPermission {
            return [
                `${PERMISSION.MODULE.GROUP_ADMIN}:${toParams.id}`,
                PERMISSION.KITA.MANAGE + toParams.id,
            ];
        },
    },
    paramDeclaration: {
        kinderOrt: KinderOrt,
    },
} satisfies KitAdminState;
